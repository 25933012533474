import React from "react";
import { Layout } from "../../../components";

const Strategia = ({ location: { pathname } }) => {
  return (
    <Layout
      seo={{
        title: "Strategy",
        href: pathname,
        lang: "en"
      }}
      langSwitch={{
        langKey: "pl",
        langSlug: "/oferta/strategia/",
      }}
    >
      <section className="single_offer">
        <div className="single_offer__container">
          <div className="single_offer__header text-center">
            <h1>Strategy</h1>
          </div>
          <div className="single_offer__content">
            <p class="text-center">Brand strategy - Communication strategy</p>
            <p class="text-center">
              A good strategy should be realistic to implement and effective. The works related to the development of the strategy include a workshop with the Client and then the auditing, analytical and strategic part. In the auditing part, we examine the current condition of the brand, what it has at its disposal, how it communicates with its target groups, and how it achieves its goals. In the analytical part, we develop: market analysis, competition analysis, target group analysis, brand review and positioning, SWOT analysis. In the strategic part, we define; communication and sales goals, brand target groups, we develop a brand communication strategy, a promotion strategy, and we sum it up with an accurate schedule of all the activities.
            </p>
            <p class="text-center">
              As a result of our work, we create a document of several dozen or several hundred pages with brand analysis, communication and promotion strategy,  and the roadmap of all of the activities. We finalize our work with a workshop meeting with our Client to discuss the final document containing the strategy prepared by us.
            </p>
          </div>
        </div>
      </section>
    </Layout>
  );
};
export default Strategia;
